export function passwordFieldToggle() {
    $("body")
        .off()
        .on("click", ".check-password", function () {
            const $this = $(this);
            const $password = $this.prev();
            if ($password.attr("type") === "password") {
                $password.attr("type", "text");
                $this.find("img").attr("src", "/svg/eye-crossed.svg");
            } else {
                $password.attr("type", "password");
                $this.find("img").attr("src", "/svg/eye.svg");
            }
        });
}
